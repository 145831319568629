import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';

const StakeholderBenefits = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.financial_institutions}`,
    message: (
      <>
        <h4>{messages.shared_platform}</h4>
        <div className="ui bulleted list">
          <div className="item">{messages.message_stakeholder_benefits_item1}</div>
          <div className="item">{messages.message_stakeholder_benefits_item2}</div>
          <div className="item">{messages.message_stakeholder_benefits_item3}</div>
          <div className="item">{messages.message_stakeholder_benefits_item4}</div>
        </div>
      </>
    )
  }, {
    header: `${messages.Interoperability}`,
    message: (
      <div className="ui bulleted list">
        <div className="item">{messages.message_stakeholder_benefits_item5}</div>
        <div className="item">{messages.message_stakeholder_benefits_item6}</div>
      </div>
    )
  }, {
    header: `${messages.government}`,
    message: (
      <>
        <h4>{messages.financial_inclusion}</h4>
        <div className="ui bulleted list">
          <div className="item">{messages.message_stakeholder_benefits_item7}</div>
          <div className="item">{messages.message_stakeholder_benefits_item8}</div>
          <div className="item">{messages.message_stakeholder_benefits_item9}</div>
          <div className="item">{messages.message_stakeholder_benefits_item10}</div>
          <div className="item">{messages.message_stakeholder_benefits_item11}</div>
          <div className="item">{messages.message_stakeholder_benefits_item12}</div>
        </div>
      </>
    )
  }, {
    header: `${messages.society}`,
    message: (
      <>
        <h4>{messages.message_stakeholder_benefits_item13}</h4>
        <div className="ui bulleted list">
          <div className="item">{messages.message_stakeholder_benefits_item14}</div>
          <div className="item">{messages.message_stakeholder_benefits_item15}</div>
          <div className="item">{messages.message_stakeholder_benefits_item16}</div>
          <div className="item">{messages.message_stakeholder_benefits_item17}</div>
          <div className="item">{messages.message_stakeholder_benefits_item18}</div>
        </div>
      </>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.stakeholder_benefits}`} textBlocks={textBlocks}/>);
};

export default StakeholderBenefits;